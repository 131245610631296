/*
 * Configuration
 */

const ENVIRONMENTS = {
  DEV: 'development',
  STAGING: 'staging',
  PROD: 'production'
};

const config = {
  [ENVIRONMENTS.DEV]: {
    URLS: {
      API_URL: '//0.0.0.0:4001',
      CLIENT: '//0.0.0.0:8100'
    },
    BUGSNAG_NOTIFIER_KEY: 'MY_API_KEY',
    reCAPTCHA: {
      sitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
    }
  },
  [ENVIRONMENTS.STAGING]: {
    URLS: {
      API_URL: 'https://kintla-safety.clients.sntestlab.com/api',
      CLIENT: 'https://kintla-safety.clients.sntestlab.com'
    },
    BUGSNAG_NOTIFIER_KEY: '8a0e960d6e650a0f4ef55177b98da1fd',
    reCAPTCHA: {
      sitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
    }
  },
  [ENVIRONMENTS.PROD]: {
    URLS: {
      API_URL: 'https://safety.handsatwork.com/api',
      CLIENT: 'https://safety.handsatwork.com'
    },
    BUGSNAG_NOTIFIER_KEY: '090cdc72292f3dee5094a230366ca0dc',
    reCAPTCHA: {
      sitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
    }
  }
};

export const hostname = {
  get: () => window.location.hostname
};

export const heuristicEnvironment = () => {
  const _hostname = hostname.get();
  if (_hostname) {
    if (
      _hostname.includes('0.0.0.0') ||
      _hostname.includes('127.0.0.1') ||
      _hostname.includes('localhost')
    ) {
      return ENVIRONMENTS.DEV;
    }
    if (_hostname.includes('sntestlab')) {
      return ENVIRONMENTS.STAGING;
    }
  }
  // Default to Production
  return ENVIRONMENTS.PROD;
};

const env = heuristicEnvironment();
export default config[env];
