/*
  NOTE: This is copied and modifed from Material UI (Not using styled components)
*/

import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
        name: "foreman",
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.foreman, query);
  const parts = parse(suggestion.foreman, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ),
        )}
      </div>
    </MenuItem>
  );
}

const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class ForemanAutosuggest extends React.Component {
  state = {
    suggestions: []
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    const {suggestionAPI} = this.props;
    const inputValue = deburr(value.trim()).toLowerCase();
    if (inputValue.length >= 3) {
      suggestionAPI(inputValue).then((res) => {
        return this.setState({ suggestions: res });
      });
    } else {
      this.setState({ suggestions: [] })
    }
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { classes, label, onAutosuggestClick, onChange, value } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: onAutosuggestClick,
      renderSuggestion,
    };

    return (

      <div>
        <Autosuggest
            {...autosuggestProps}
            inputProps={{
              classes,
              label: label,
              value: value,
              onChange: onChange,
              inputRef: node => {
                this.popperNode = node;
              },
              InputLabelProps: {
                shrink: true,
              },
            }}
            theme={{
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={options => (
              <Popper anchorEl={this.popperNode} open={Boolean(options.children)}>
                <Paper
                  square
                  {...options.containerProps}
                  style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
                >
                  {options.children}
                </Paper>
              </Popper>
            )}
          />
      </div>
    );
  }
}

ForemanAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForemanAutosuggest);
