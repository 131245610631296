import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import userService from '../services/user.service';

const StyledMain = styled.main`
  width: 400px;
  margin: 0 auto;
`;

const StyledPaper = styled(Paper)`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 24px;
`;

const StyledForm = styled.form`
  width: 100%;
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

const StyledSnackbar = styled(Snackbar)`
  top: 20px !important;
`;

const StyledSnackbarContent = styled(SnackbarContent)`
  background-color: #ff0000 !important;
  color: #fff !important;
  font-weight: bold !important;
  margin-top: 20px;
`;

class LoginPage extends React.Component {

  constructor(props) {
    super(props);

    userService.logout();

    this.state = {
      email: '',
      password: '',
      submitted: false,
      loading: false,
      errorMessage: '',
      showError: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    // stop here if form is invalid
    if (!(email && password)) {
      return;
    }

    this.setState({ loading: true });
    userService.login(email, password).then(
      () => window.location.href = '/'
    ).catch((e) => {
      this.setState({ errorMessage: e.message, showError: true, loading: false });
    });
  }

  handleClose = () => {
    this.setState({ showError: false });
  };


  render() {
    return (
      <StyledMain>
        <CssBaseline />
        <StyledPaper>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <StyledForm onSubmit={this.handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel shrink >Email Address</InputLabel>
              <Input id="email" name="email" autoComplete="email" autoFocus onChange={this.handleChange} />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password" shrink >Password</InputLabel>
              <Input name="password" type="password" id="password" autoComplete="current-password" onChange={this.handleChange} />
            </FormControl>
            <ButtonContainer>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={this.state.loading}
              >
                Sign in
              </Button>
            </ButtonContainer>
            <StyledSnackbar
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              open={this.state.showError}
              onClose={this.handleClose}
              message={<span id="message-id">{this.state.errorMessage}</span>}
            >
              <StyledSnackbarContent
                onClose={this.handleClose}
                message={<span id="message-id">{this.state.errorMessage}</span>}
              />
            </StyledSnackbar>
          </StyledForm>
        </StyledPaper>
      </StyledMain>
    );
  }
}

export default LoginPage;
