import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import userService from '../services/user.service';
import AppBar from './AppBar';
import SafetyDataTable from './SafetyDataTable';
import Grid from '@material-ui/core/Grid';
import api from '../util/api';

const StyledMain = styled.main`
  margin: 30px auto;
  width: 80%;
`;

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {}
    };

    this.exportSafetyData = this.exportSafetyData.bind(this);
  }

  componentDidMount() {
    userService.userProfile().then(user => this.setState({ user }))
    .catch((error)=> {
      console.log(error);
      if (error.statusCode === 401) {
        userService.logout();
        window.location.href = '/login'
      }
    });
  }

  exportSafetyData() {
    api.exportSafetyData().then((response) => {
      let a = document.createElement('a');
      a.href = URL.createObjectURL(response);
      a.target = '_blank';
      a.click();
    });
  }

  navigateToSafetyCard() {
    window.location.href ='/safety-card'
  }

  render() {
    const { user } = this.state;
    return (
      <div>
        <AppBar />
        <StyledMain>
          <CssBaseline />
          <Grid container justify="space-between" spacing={24}>
            <Grid item >
              <Typography component="h1" variant="h5">
                Welcome {user.firstName}!
              </Typography>
            </Grid>
            <Grid item >
              <Button variant="contained" color="primary" onClick={this.exportSafetyData}>
                Export Data
              </Button>
            </Grid>
          </Grid>
          <SafetyDataTable />
        </StyledMain>
      </div>
    );
  }
}

export default HomePage;
