import config from './config/app';

const DEFAULT_OPTIONS = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  mode: 'cors',
  credentials: 'include'
};

const authHeader = () => {
  // return authorization header with basic auth credentials
  const authToken = localStorage.getItem('access_token');
  if (authToken) {
      return { 'Authorization': 'Bearer ' + authToken };
  } else {
      return {};
  }
}

const processResponse = async response => {
  const parsedResponse = await response.json();
  if (!response.ok) {
    throw new HttpError(response, parsedResponse);
  }

  return parsedResponse;
};

const processBlob = async response => {
  const parsedResponse = await response.blob();
  if (!response.ok) {
    throw new HttpError(response, parsedResponse);
  }

  return parsedResponse;
};

const mergeOptions = (options, method) => {
  const headers = {
    ...authHeader(),
    ...DEFAULT_OPTIONS.headers,
    ...options.headers
  };
  return { ...DEFAULT_OPTIONS, ...options, headers, method };
};

const resolveUrl = (url, base = config.URLS.API_URL) => {
  url = url || '';
  return (url.startsWith('//') || url.startsWith('http')) ? url : `${base}${url}`;
};


export const get = (url, options = {}) =>
  fetch(resolveUrl(url), mergeOptions(options, 'GET')).then(processResponse);

export const getBlob = (url, options = {}) =>
  fetch(resolveUrl(url), mergeOptions(options, 'GET')).then(processBlob);


export const post = (url, options = {}) =>
  fetch(resolveUrl(url), mergeOptions(options, 'POST')).then(processResponse);

export const put = (url, options = {}) =>
  fetch(resolveUrl(url), mergeOptions(options, 'PUT')).then(processResponse);

  export function HttpError(response = {}, parsedBody = {}) {
    this.name = 'HttpError';
    this.message = response.statusText;
    this.stack = new Error().stack;
    this.response = response;
    this.statusCode = response.status;
    this.body = parsedBody;
  }
  HttpError.prototype = new Error();
