import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import RadioButtonList from './RadioButtonList';
import Typography from '@material-ui/core/Typography';
import ForemanAutosuggest from './ForemanAutosuggest';
import TaskAutosuggest from './TaskAutosuggest';
import AppBar from './AppBar';
import api from '../util/api';
import userService from '../services/user.service';

import Select from '@material-ui/core/Select';

const StyledMain = styled.main`
  margin: 0 auto;
  width: 80%;
`;

const StyledPaper = styled(Paper)`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 24px;
`;

const StyledForm = styled.form`
  width: 100%;
  margin-top: 8px;
  flex-grow: 1;
  display: block;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

const PreviousIdH3 = styled.h3`
  margin: 0;
`;

const StyledSnackbar = styled(Snackbar)`
  top: 20px !important;
`;

const StyledSnackbarContent = styled(SnackbarContent)`
  background-color: #ff0000 !important;
  color: #fff !important;
  font-weight: bold !important;
  margin-top: 20px;
`;


class SafetyCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: '',
      shift: '',
      task: '',
      company: '',
      superintendent: '',
      generalForeman: '',
      foreman: '',
      pillar: '',
      taskSummary: '5',
      safetyAwareness: '5',
      taskSteps: '5',
      taskStepRisks: '5',
      riskReductionActions: '5',
      specialRisks: '5',
      mindOnSafety: '5',
      signatures: '5',
      submitted: false,
      loading: false,
      errorMessage: '',
      showError: false,
      previousId: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForemanAutofill = this.handleForemanAutofill.bind(this);
    this.handleTaskAutofill = this.handleTaskAutofill.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  resetForm() {
    this.setState({
      date: '',
      shift: '',
      task: '',
      company: '',
      superintendent: '',
      generalForeman: '',
      foreman: '',
      pillar: '',
      taskSummary: '5',
      safetyAwareness: '5',
      taskSteps: '5',
      taskStepRisks: '5',
      riskReductionActions: '5',
      specialRisks: '5',
      mindOnSafety: '5',
      signatures: '5',
      submitted: false,
      loading: false
    });
  }

  handleClose = () => {
    this.setState({ showError: false });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleRadioChange(e) {
    const { name } = e.target;
    this.setState({ [name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });
    api.submitSafetyCard(this.state).then((res) => {
      this.setState({ loading: false, previousId: res.id });
      this.resetForm();
    }).catch((err) => {
      if (err.statusCode === 401) {
        userService.logout();
        window.location.href = '/login'
      }
      this.setState({ loading: false, errorMessage: err.message, showError: true });
    });
  }

  handleTaskAutofill(item) {
    this.setState({task: item.label});
  }

  handleForemanAutofill(item) {
    this.setState({
      foreman: item.foreman,
      company: item.company,
      superintendent: item.superintendent,
      generalForeman: item.general_foreman,
      pillar: item.work_area
     });
  }

  render() {
    return (
      <div>
        <AppBar />
        <StyledMain>
          <CssBaseline />
          <StyledPaper>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={6}>
                <Typography component="h1" variant="h5" align="left">
                  Safety Card
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {this.state.previousId &&
                  <PreviousIdH3>Previous ID: {this.state.previousId}</PreviousIdH3>
                }
              </Grid>
            </Grid>
            <StyledForm onSubmit={this.handleSubmit}>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <ForemanAutosuggest
                      label="Foreman *"
                      suggestionAPI={api.lookupForeman}
                      onAutosuggestClick= {this.handleForemanAutofill}
                      onChange={this.handleChange}
                      value={this.state.foreman} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel shrink >Company</InputLabel>
                    <Input id="company" name="company" value={this.state.company} onChange={this.handleChange} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel shrink >Superintendent</InputLabel>
                    <Input id="superintendent" name="superintendent" value={this.state.superintendent} onChange={this.handleChange} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel shrink >General Foreman</InputLabel>
                    <Input id="general-foreman" name="generalForeman" value={this.state.generalForeman} onChange={this.handleChange} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel shrink >Pillar</InputLabel>
                    <Input id="pillar" name="pillar" value={this.state.pillar} onChange={this.handleChange} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      id="date"
                      label="Date"
                      type="date"
                      name="date"
                      value={this.state.date}
                      required
                      onChange={this.handleChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel shrink>Shift</InputLabel>
                    <Select
                      native
                      value={this.state.shift}
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'shift',
                        id: 'shift',
                      }}
                    >
                      <option value=''/>
                      <option value={'Morning'}>Morning 0700AM-0930AM</option>
                      <option value={'Mid Morning'}>Mid Morning 0930AM-1200PM</option>
                      <option value={'Afternoon'}>Afternoon 1230PM-0300PM</option>
                      <option value={'Late Afternoon'}>Late Afternoon 0300PM-0530PM</option>
                      <option value={'Night'}>Night Shift</option>
                      <option value={'Not Listed'}>Not Listed</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="normal" required fullWidth>
                    <TaskAutosuggest
                      label="Task *"
                      onChange={this.handleChange}
                      value={this.state.task}
                      onAutosuggestClick={this.handleTaskAutofill} />
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={24}>
                <Grid item xs={12} sm={6}>
                  <FormLabel component="legend"></FormLabel>
                  <RadioButtonList
                    label="Task Summary &amp; Supervision A"
                    handler = {this.handleRadioChange}
                    name = "taskSummary"
                    selectedValue = {this.state.taskSummary}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormLabel component="legend"></FormLabel>
                  <RadioButtonList
                    label="Access to lower Risk D"
                    handler = {this.handleRadioChange}
                    name = "riskReductionActions"
                    selectedValue = {this.state.riskReductionActions}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel component="legend"></FormLabel>
                  <RadioButtonList
                    label="Safety Awareness A"
                    handler = {this.handleRadioChange}
                    name = "safetyAwareness"
                    selectedValue = {this.state.safetyAwareness}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel component="legend"></FormLabel>
                  <RadioButtonList
                    label="Special Risk E"
                    handler = {this.handleRadioChange}
                    name = "specialRisks"
                    selectedValue = {this.state.specialRisks}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel component="legend"></FormLabel>
                  <RadioButtonList
                    label="Task Steps B"
                    handler = {this.handleRadioChange}
                    name = "taskSteps"
                    selectedValue = {this.state.taskSteps}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel component="legend"></FormLabel>
                  <RadioButtonList
                    label="Mind on Safety F"
                    handler = {this.handleRadioChange}
                    name = "mindOnSafety"
                    selectedValue = {this.state.mindOnSafety}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel component="legend"></FormLabel>
                  <RadioButtonList
                    label="Risks of Step C"
                    handler = {this.handleRadioChange}
                    name = "taskStepRisks"
                    selectedValue = {this.state.taskStepRisks}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel component="legend"></FormLabel>
                  <RadioButtonList
                    label="Signatures G"
                    handler = {this.handleRadioChange}
                    name = "signatures"
                    selectedValue = {this.state.signatures}
                    />
                </Grid>
              </Grid>
              <ButtonContainer>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={this.state.loading}
                >
                  Submit
                </Button>
              </ButtonContainer>
            </StyledForm>
          </StyledPaper>
          <StyledSnackbar
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              open={this.state.showError}
              onClose={this.handleClose}
              message={<span id="message-id">{this.state.errorMessage}</span>}
            >
              <StyledSnackbarContent
                onClose={this.handleClose}
                message={<span id="message-id">{this.state.errorMessage}</span>}
              />
            </StyledSnackbar>
        </StyledMain>
      </div>
    );
  }
}

export default SafetyCard;
