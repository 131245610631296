import { get, post, getBlob } from '../http';

const api = {
  auth: {
    login: (email, password, recaptcha = 'dummy') => {
      const payload = {
        email: email,
        password: password,
        recaptcha: recaptcha
      };

      return post('/auth', {body: JSON.stringify(payload)})
    }
  },

  getUserData: () => get('/me'),

  submitSafetyCard: (form) => {
    const payload = {
      date: new Date(form.date),
      shift: form.shift,
      task: form.task,
      company: form.company,
      superintendent: form.superintendent,
      generalForeman: form.generalForeman,
      foreman: form.foreman,
      pillar: form.pillar,
      taskSummary: parseInt(form.taskSummary, 10),
      safetyAwareness: parseInt(form.safetyAwareness, 10),
      taskSteps: parseInt(form.taskSteps, 10),
      taskStepRisks: parseInt(form.taskStepRisks, 10),
      riskReductionActions: parseInt(form.riskReductionActions, 10),
      specialRisks: parseInt(form.specialRisks, 10),
      mindOnSafety: parseInt(form.mindOnSafety, 10),
      signatures: parseInt(form.signatures, 10),
    };
    return post('/safety-data', {body: JSON.stringify(payload)})
  },

  lookupForeman: (query) => {
    const payload = {
     query: query
    };
    return post('/safety-data/lookup', {body: JSON.stringify(payload)})
  },

  listSafetyData: () => get('/safety-data'),

  exportSafetyData: () => getBlob('/safety-data/export')
}

export default api;

export function withErrorHandling(defaultErrorAction) {
  return (error) => {
    if (error.statusCode === 401) {
      return {type: 'AUTH_FAILURE', error: 'Not authorized'};
    } else {
      return defaultErrorAction(error);
    }
  };
}
