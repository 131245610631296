import api from '../util/api';

const userService = {
    login: function login(email, password) {
      return api.auth.login(email, password)
        .then(user => {
          localStorage.setItem('access_token', user.token);
        })
    },

    logout: function logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('access_token');
    },

    userProfile: function userProfile() {
      return api.getUserData();
    }
};

export default userService;


