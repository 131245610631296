import React, { Component } from 'react';
import './App.css';
import bugsnag from 'bugsnag-js';
import config from './config/app';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import SafetyCard from './components/SafetyCard';
import { ErrorScreen } from './components/ErrorScreen';
import { PrivateRoute } from './components/PrivateRoute';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import createPlugin from 'bugsnag-react';

export const bugsnagClient = bugsnag({
  apiKey: config.BUGSNAG_NOTIFIER_KEY
});

const ErrorBoundary = bugsnagClient.use(createPlugin(React));

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <ErrorBoundary FallbackComponent={ErrorScreen}>
            <Switch>
              <PrivateRoute exact path="/" component={HomePage} />
              <PrivateRoute exact path="/safety-card" component={SafetyCard} />
              <Route exact path="/login" component={LoginPage} />
              <Route component={ErrorScreen} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
