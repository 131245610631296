import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import Radio from '@material-ui/core/Radio';


class RadioButtonList extends React.Component {
  render() {
    const { label, name, handler } = this.props;
    return (
      <div>
        <FormLabel component="legend">{label}</FormLabel>
          <FormControlLabel
            control={
              <Radio
                checked={this.props.selectedValue === '0'}
                onChange={handler}
                value="0"
                name={name}
                aria-label="0"
              />
            }
            label="0"
          />
          <FormControlLabel
            control={
              <Radio
                checked={this.props.selectedValue === '1'}
                onChange={handler}
                value="1"
                name={name}
                aria-label="1"
              />
            }
            label="1"
          />
          <FormControlLabel
            control={
              <Radio
                checked={this.props.selectedValue === '2'}
                onChange={handler}
                value="2"
                name={name}
                aria-label="2"
              />
            }
            label="2"
          />
          <FormControlLabel
            control={
              <Radio
                checked={this.props.selectedValue === '3'}
                onChange={handler}
                value="3"
                name={name}
                aria-label="3"
              />
            }
            label="3"
          />
          <FormControlLabel
            control={
              <Radio
                checked={this.props.selectedValue === '4'}
                onChange={handler}
                value="4"
                name={name}
                aria-label="4"
              />
            }
            label="4"
          />
          <FormControlLabel
            control={
              <Radio
                checked={this.props.selectedValue === '5'}
                onChange={handler}
                value="5"
                name={name}
                aria-label="5"
              />
            }
            label="5"
          />
      </div>
    );
  }
}

export default RadioButtonList;
